import { CartModel } from '@nimles/models';
import { createCart, loadCarts, updateCart } from '@nimles/react-redux';

const EmptyGuid = '00000000-0000-0000-0000-000000000000';

let cartPromise = Promise.resolve<CartModel>(null);

export const loadOrCreateCart = async (dispatch: any) => {
  cartPromise = cartPromise.then(async (cart) => {
    try {
      if (!cart) {
        const queryParameters = {};
        const cartUser = localStorage.getItem('cartUser') ?? null;
        if (cartUser) {
          queryParameters['cartUser'] = cartUser;
        }
        const carts = await dispatch(loadCarts(queryParameters));
        if (carts?.length) {
          cart = carts[0];
        } else {
          cart = await dispatch(createCart({}));
        }
        localStorage.setItem('cartUser', cart.cartUser);
      }
    } catch (error) {
      console.error(error);
    }

    return cart;
  });

  return await cartPromise;
};

export const addProduct = async (
  dispatch: any,
  productId: string,
  quantity: number = 1
) => {
  cartPromise = cartPromise.then(async (cart) => {
    try {
      if (cart) {
        const cartItem = cart.items?.find(
          (item) => item.productId === productId
        );
        if (cartItem) {
          cartItem.quantity += quantity;
        } else {
          if (!cart.items) {
            cart.items = [];
          }
          cart.items.push({ productId, quantity });
        }
        if (!cart.id || cart.id === EmptyGuid) {
          cart = await dispatch(createCart(cart));
        } else {
          cart = await dispatch(updateCart(cart));
        }
      }
    } catch (error) {
      console.error(error);
    }

    return cart;
  });

  return await cartPromise;
};

export const updateItem = async (
  dispatch: any,
  productId: string,
  quantity: number
) => {
  cartPromise = cartPromise.then(async (cart) => {
    try {
      if (cart) {
        const cartItem = cart.items?.find(
          (item) => item.productId === productId
        );
        if (cartItem) {
          cartItem.quantity = quantity;
        } else {
          if (!cart.items) {
            cart.items = [];
          }
          cart.items.push({ productId, quantity });
        }
        if (!cart.id || cart.id === EmptyGuid) {
          cart = await dispatch(createCart(cart));
        } else {
          cart = await dispatch(updateCart(cart));
        }
      }
    } catch (error) {
      console.error(error);
    }

    return cart;
  });

  return await cartPromise;
};

import React from 'react';
import { UserModel, PageModel } from '@nimles/models';
import { useStaticQuery, graphql } from 'gatsby';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/types';
import { Link } from 'gatsby-plugin-react-i18next';

export const PageLink = ({ authRestriction, pageId }) => {
  const user = useSelector<RootState, UserModel>(
    ({ currentUser }) => currentUser.user
  );
  const data = useStaticQuery(graphql`
    query ElementPagesQuery {
      cms {
        pages {
          edges {
            node {
              id
              organizationId
              pageType
              title
              uniqueName
              summary
              elements {
                ...NimlesCMS_ElementPublicViewModelFragment
                children {
                  ...NimlesCMS_ElementPublicViewModelFragment
                  children {
                    ...NimlesCMS_ElementPublicViewModelFragment
                    children {
                      ...NimlesCMS_ElementPublicViewModelFragment
                      children {
                        ...NimlesCMS_ElementPublicViewModelFragment
                        children {
                          ...NimlesCMS_ElementPublicViewModelFragment
                          children {
                            ...NimlesCMS_ElementPublicViewModelFragment
                            children {
                              ...NimlesCMS_ElementPublicViewModelFragment
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const pages: PageModel[] = data.cms.pages.edges.map(({ node }) => node);

  const page = pages.find(({ id }) => id === pageId);

  return page &&
    (user?.id
      ? authRestriction !== 'notAuthenticated'
      : authRestriction !== 'authenticated') ? (
    <Link to={'/' + page.uniqueName}>{page.title}</Link>
  ) : null;
};

import React from 'react';

export const SvgCart = () => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    className="symbol symbol-fill"
    data-reactid=".0.$625.1"
  >
    <use xlinkHref="#icon-625" data-reactid=".0.$625.1.0">
      <path
        id="icon-625"
        d="M14,34c0-2.197,2.665-3.006,2.775-3.038l0.099-0.028l25.968-1.995L46.205,11H11.096L8.778,4.182L1.275,2.039l-0.55,1.923 l6.497,1.856l8.03,23.607C14.002,30.023,12,31.389,12,34c0,3.164,3.271,4,5,4h27v-2H17.003C16.88,35.999,14,35.959,14,34z M11.776,13h32.019l-2.637,14.062l-23.971,1.842L11.776,13z M20.001,40c-1.93,0-3.5,1.57-3.5,3.5s1.57,3.5,3.5,3.5s3.5-1.57,3.5-3.5 S21.931,40,20.001,40z M20.001,45c-0.827,0-1.5-0.673-1.5-1.5s0.673-1.5,1.5-1.5s1.5,0.673,1.5,1.5S20.828,45,20.001,45z M36,40 c-1.93,0-3.5,1.57-3.5,3.5S34.07,47,36,47s3.5-1.57,3.5-3.5S37.93,40,36,40z M36,45c-0.827,0-1.5-0.673-1.5-1.5S35.173,42,36,42 s1.5,0.673,1.5,1.5S36.827,45,36,45z"
      />
    </use>
  </svg>
);

import { faLinkedin } from '@fortawesome/fontawesome-free-brands';
import {
  faFacebookSquare,
  faTwitter,
  faInstagram,
  faPinterest,
  faFacebook,
  faFacebookF,
} from '@fortawesome/free-brands-svg-icons';
import { ElementModel, LocationModel } from '@nimles/models';

const fileBaseUrl = 'https://media.nimles.com/file/';

export const getImageUrl = (imageId: string, width?: number) =>
  imageId &&
  fileBaseUrl +
    process.env.GATSBY_TENANT +
    '/' +
    imageId +
    (width ? '?width=' + width : '');

export const getFileUrl = (fileId: string, width?: number) =>
  fileId && fileBaseUrl + process.env.GATSBY_TENANT + '/' + fileId;

export const compareBy = (
  key: string,
  secondKey?: string,
  descending?: boolean
) => {
  return (a, b) => {
    if (a[key] < b[key]) {
      return descending ? 1 : -1;
    }
    if (a[key] > b[key]) {
      return descending ? -1 : 1;
    }
    if (secondKey) {
      if (a[secondKey] < b[secondKey]) {
        return descending ? 1 : -1;
      }
      if (a[secondKey] > b[secondKey]) {
        return descending ? -1 : 1;
      }
    }
    return 0;
  };
};

export function createPassword() {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (
    c
  ) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}
export var groupByCount = function (items, key) {
  let item = items.find((i) => i.key == key);
  if (item) {
    item.value++;
  } else {
    items.push({ key, value: 1 });
  }
  return items;
};

export const toUniqueName = (input: string) =>
  input
    .toLowerCase()
    .replace('   ', '-')
    .replace('  ', '-')
    .replace(' ', '-')
    .replace('ä', 'a')
    .replace('å', 'a')
    .replace('ö', 'o')
    .replace(/[^(a-z0-9-)*]/g, '')
    .toLowerCase();

export function getContent(element: ElementModel, type: string) {
  if (element?.type?.toLowerCase() === type) {
    return element.content;
  }
  if (!element?.children) {
    return;
  }
  for (const child of element.children) {
    const content = getContent(child, type);
    if (content) {
      return content;
    }
  }
}

export const socialArray: {
  name: string;
  displayName: string;
  icon: any;
  background: string;
  value: string;
}[] = [
  {
    name: 'facebook',
    displayName: 'Facebook',
    icon: faFacebookF,
    background: '#3b5998',
    value: 'https://www.facebook.com/gardsbutiker.se/',
  },
  {
    name: 'twitter',
    displayName: 'Twitter',
    icon: faTwitter,
    background: '#00aced',
    value: 'https://twitter.com/Grdsbutiker1/',
  },
  {
    name: 'instagram',
    displayName: 'Instagram',
    icon: faInstagram,
    background: '#517fa4',
    value: 'https://www.instagram.com/gardsbutiker.se/',
  },
  {
    name: 'pinterest',
    displayName: 'Pinterest',
    icon: faPinterest,
    background: '#cb2027',
    value: 'https://www.pinterest.com/gardsbutiker/',
  },
  {
    name: 'linkedin',
    displayName: 'LinkedIn',
    icon: faLinkedin,
    background: '#007bb6',
    value: 'https://www.pinterest.com/gardsbutiker/',
  },
];

export const distance = (
  location1: LocationModel,
  location2: LocationModel,
  unit?: string
) => {
  const { latitude: lat1, longitude: lng1 } = location1;
  const { latitude: lat2, longitude: lon2 } = location2;

  if (lat1 == lat2 && lng1 == lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lng1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == 'K') {
      dist = dist * 1.609344;
    }
    if (unit == 'N') {
      dist = dist * 0.8684;
    }
    return dist;
  }
};

import React, { useEffect } from 'react';
import { CartModel } from '@nimles/models';
import { State } from '@nimles/react-redux';
import { List, Sidenav } from '@nimles/react-web-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/types';
import { CartItem } from '../CartItem';
import { CartContext } from '../context/cartContext';
import { loadOrCreateCart } from '../../../services/cartService';

export const CartMenu = () => {
  const dispatch = useDispatch();
  const { selected: cart } = useSelector<RootState, State<CartModel>>(
    ({ carts }) => carts
  );

  useEffect(() => {
    if (!cart) {
      loadOrCreateCart(dispatch);
    }
  }, []);

  return (
    <CartContext.Consumer>
      {({ isCartOpen, setCartOpen }) => (
        <Sidenav open={isCartOpen} onClose={() => setCartOpen(false)}>
          <List>
            {cart?.items?.map((item) => (
              <CartItem item={item} />
            ))}
          </List>
        </Sidenav>
      )}
    </CartContext.Consumer>
  );
};

/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import {
  OrganizationModel,
  CategoryModel,
  UserModel,
  ProductGroupModel,
  TenantModel,
  PageModel,
  ProductModel,
} from '@nimles/models';
import {
  forgotPassword,
  loadCurrentUser,
  signInUser,
} from '@nimles/react-redux';
import { Button, elements, Image, Section } from '@nimles/react-web-components';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { I18nextContext, Link, useI18next } from 'gatsby-plugin-react-i18next';
import React, { useContext, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BannerSection } from '../../components/banner/BannerSection';
import { Brand } from '../../components/brand/Brand';
import { CartButton } from '../../components/cart/button/CartButton';
import { Cart } from '../../components/cart/Cart';
import { CategoryGrid } from '../../components/category/grid/CategoryGrid';
import { SignInForm } from '../../components/forms/signIn/SignInForm';
import { LanguageSelector } from '../../components/language/LanguageSelector';
import LicensePlateSearch from '../../components/LicensePlateSearch';
import { OrganizationGrid } from '../../components/organization/grid/OrganizationGrid';
import { PageLink } from '../../components/pageLink/PageLink';
import { ProductGrid } from '../../components/product/grid/ProductGrid';
import { ProductGroupGrid } from '../../components/productGroup/grid/ProductGroupGrid';
import { Search } from '../../components/Search';
import { RootState } from '../../redux/types';
import { getImageUrl } from '../../utils';

const NavButton = Button.withComponent(Link);

const UserMenuButton = styled.button`
  background: transparent;
  cursor: pointer;
`;

const UserMenu = () => {
  const user = useSelector<RootState, UserModel>(
    ({ currentUser }) => currentUser.user
  );

  return !user?.id ? null : (
    <UserMenuButton onClick={() => navigate('/account/profile')}>
      {user?.firstName}
    </UserMenuButton>
  );
};

const OrganizationMenu = () => {
  const organization = useSelector<RootState, OrganizationModel>(
    ({ organizations }) => organizations.selected
  );

  return !organization?.id ? null : (
    <UserMenuButton onClick={() => navigate('/company/profile')}>
      {organization?.name}
    </UserMenuButton>
  );
};

const SignIn = () => {
  const dispatch = useDispatch();

  const handleSubmit = async (values: any) => {
    await dispatch(signInUser(values));
    await dispatch(loadCurrentUser());
    navigate('/account/profile');
  };

  const handleForgotPassword = async (username: string) => {
    await dispatch(forgotPassword(username));
  };

  return (
    <SignInForm
      onSubmit={handleSubmit}
      onForgotPassword={handleForgotPassword}
    />
  );
};

export const Component: FC = ({ children }) => {
  const data = useStaticQuery(graphql`
    query ElementsQuery {
      identity {
        tenants {
          edges {
            node {
              name
              logoId
              bannerId
            }
          }
        }
      }
      organizationManagement {
        organizations {
          edges {
            node {
              id
              name
              description
              uniqueName
              phoneNumbers
              emails
              website
              bannerId
              logoId
              address {
                id
                street
                postalCode
                city
                state
              }
              location {
                id
                latitude
                longitude
              }
              socialLinks
              tags
            }
          }
        }
        categories {
          edges {
            node {
              id
              name
              summary
              description
              uniqueName
              bannerId
              logoId
              tags
            }
          }
        }
      }
      pim {
        products {
          edges {
            node {
              id
              organizationId
              name
              uniqueName
              summary
              description
              properties {
                id
                productPropertyId
                stringValue
                numberValue
              }
              imageIds
              netPrice
              grossPrice
            }
          }
        }
        productGroups {
          edges {
            node {
              id
              uniqueName
              name
              summary
              description
              parentId
              children {
                id
                uniqueName
                name
                summary
                description
                parentId
              }
            }
          }
        }
      }
    }
  `);

  const organizations: OrganizationModel[] = data.organizationManagement.organizations.edges.map(
    ({ node }) => node
  );
  const categories: CategoryModel[] = data.organizationManagement.categories.edges.map(
    ({ node }) => node
  );
  const products: ProductModel[] = data.pim.products.edges.map(
    ({ node }) => node
  );
  const productGroups: ProductGroupModel[] = data.pim.productGroups.edges.map(
    ({ node }) => node
  );
  const tenants: TenantModel[] = data.identity.tenants.edges.map(
    ({ node }) => node
  );

  const tenant = tenants?.length ? tenants[0] : null;

  const handleSubmit = ({
    query,
    position,
    organization,
    category,
    region,
  }) => {
    if (query) {
      navigate('/karta/?query=' + query);
    } else if (position) {
      navigate(`/karta/?lat=${position?.latitude}&lng=${position?.longitude}`);
    } else if (organization) {
      navigate('/' + organization.uniqueName);
    } else if (category) {
      navigate('/' + category.uniqueName + '-c');
    } else if (region) {
      navigate('/' + region.toLowerCase());
    }
  };

  elements.section = ({
    element: { properties = {}, uniqueName },
    children,
    generatedCss,
  }) =>
    uniqueName === 'banner' ? (
      <BannerSection {...properties} css={generatedCss}>
        {children}
      </BannerSection>
    ) : (
      <Section {...properties} css={generatedCss}>
        {children}
      </Section>
    );

  elements.navbutton = ({
    element: { properties = {}, content, contentLocalized },
    generatedCss,
    culture,
  }) => (
    <NavButton {...properties} to={properties.to} css={generatedCss}>
      {(culture && contentLocalized?.[culture]) ?? content}
    </NavButton>
  );

  elements.categories = ({ element: { properties = {} }, generatedCss }) => {
    return (
      <CategoryGrid
        {...properties}
        css={generatedCss}
        categories={categories}
      />
    );
  };

  elements.organizations = ({ element: { properties = {} }, generatedCss }) => {
    return (
      <OrganizationGrid
        {...properties}
        css={generatedCss}
        organizations={organizations}
      />
    );
  };

  elements.products = ({ element: { properties = {} }, generatedCss }) => {
    return (
      <ProductGrid {...properties} css={generatedCss} products={products} />
    );
  };

  elements.productgroups = ({ element: { properties = {} }, generatedCss }) => {
    return (
      <ProductGroupGrid
        {...properties}
        css={generatedCss}
        productGroups={productGroups}
      />
    );
  };

  elements.search = ({ element: { properties = {} }, generatedCss }) => (
    <Search
      {...properties}
      css={generatedCss}
      categories={categories}
      regions={process.env.GATSBY_REGIONS?.split(',') ?? []}
      organizations={organizations}
      onSubmit={handleSubmit}
    />
  );

  elements.languageselector = ({
    element: { properties = {} },
    generatedCss,
  }) => <LanguageSelector {...properties} css={generatedCss} />;

  elements.brand = ({ element: { properties = {} }, generatedCss }) => (
    <Brand
      {...properties}
      css={generatedCss}
      name={tenant?.name}
      logo={getImageUrl(tenant?.logoId)}
    />
  );
  elements.usermenu = ({ element: { properties = {} }, generatedCss }) => (
    <UserMenu {...properties} css={generatedCss} />
  );
  elements.shoppingcart = ({ element: { properties = {} }, generatedCss }) => (
    <CartButton {...properties} css={generatedCss} />
  );
  elements.organizationmenu = ({
    element: { properties = {} },
    generatedCss,
  }) => <OrganizationMenu {...properties} css={generatedCss} />;
  elements.licenseplate = ({ element: { properties = {} }, generatedCss }) => (
    <LicensePlateSearch {...properties} css={generatedCss} />
  );
  elements.signin = ({ element: { properties = {} }, generatedCss }) => (
    <SignIn {...properties} css={generatedCss} />
  );
  elements.svg = ({ element: { properties = {}, content }, generatedCss }) => (
    <img src={getImageUrl(content)} {...properties} css={generatedCss} />
  );
  elements.pagelink = ({
    element: {
      properties: { authRestriction, page: pageId, ...properties } = {},
      content,
    },
    generatedCss,
  }) => (
    <PageLink
      authRestriction={authRestriction}
      pageId={pageId}
      {...properties}
      css={generatedCss}
    />
  );

  return <>{children}</>;
};

export const InitElements: FC = React.memo(Component);

import React from 'react';
import {
  Card,
  CardAction,
  CardActions,
  CardBody,
  CardImage,
  Column,
  Row,
} from '@nimles/react-web-components';
import { Link } from 'gatsby-plugin-react-i18next';
import styled from '@emotion/styled';
import { getImageUrl } from '../../../utils';
import { CartModel, ProductModel } from '@nimles/models';
import { useDispatch, useSelector } from 'react-redux';
import { State, updateCart } from '@nimles/react-redux';
import { RootState } from '../../../redux/types';
import { Price } from '../../price/Price';
import { addProduct } from '../../../services/cartService';

const ProductLink = styled(Link)`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const Description = styled.div`
  flex: 1 0 auto;
  position: relative;
  font-size: 14px;
  line-height: 18px;
  max-height: 85px;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.surface.onColor};

  &::after {
    content: '';
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      transparent 100%
    );
    display: block;
    position: absolute;
    height: 10px;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

const Name = styled.h4`
  font-size: 16px;
  margin: 0 0 10px;
  font-family: 'Lato', sans-serif;
  flex: 1 0 auto;
`;

interface Props {
  product: ProductModel;
}

export const ProductCard = ({ product }: Props) => {
  const dispatch = useDispatch();
  const { selected: cart } = useSelector<RootState, State<CartModel>>(
    ({ carts }) => carts
  );

  const { id, name, summary, imageIds, netPrice } = product;

  const handleAddProduct = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    addProduct(dispatch, id);
  };

  return (
    <ProductLink to={`/${product.uniqueName}-pr`}>
      <Card flex>
        <CardImage
          src={getImageUrl(imageIds?.length ? imageIds[0] : null, 480)}
          alt={name}
          ratio={1.5}
          fit="contain"
        />
        <CardBody>
          <Name>{name}</Name>
          <Description dangerouslySetInnerHTML={{ __html: summary }} />
          <Row justify="center">
            <Column>
              <Price value={netPrice} />
            </Column>
          </Row>
        </CardBody>
        <CardActions justify="center">
          <CardAction primary raised onClick={handleAddProduct}>
            Lägg i varukorgen
          </CardAction>
        </CardActions>
      </Card>
    </ProductLink>
  );
};

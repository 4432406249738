import { CartItemModel, ProductModel } from '@nimles/models';
import { loadPublicProduct } from '@nimles/react-redux';
import { Column, Image, ListItem, Row } from '@nimles/react-web-components';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/types';
import { updateItem } from '../../services/cartService';
import { getImageUrl } from '../../utils';
import { Price } from '../price/Price';
import { Quantity } from '../quantity/Quantity';

export const CartItem = ({
  item: { productId, quantity },
}: {
  item: CartItemModel;
}) => {
  const dispatch = useDispatch();
  const products = useSelector<RootState, ProductModel[]>(
    ({ publicProducts }) => publicProducts.values
  );

  const product = products.find(({ id }) => id === productId);

  useEffect(() => {
    if (productId && !product) {
      dispatch(loadPublicProduct(productId));
    }
  }, [productId, product?.id]);

  const handleQuantityChange = (value) => {
    updateItem(dispatch, productId, value);
  };

  return (
    <ListItem>
      {product ? (
        <div>
          <Row align="center" margin="0 -15px 10px">
            <Column xs={30}>
              {product.imageIds?.length ? (
                <Image src={getImageUrl(product.imageIds[0])} />
              ) : null}
            </Column>
            <Column flex>
              <div>
                <strong>{product?.name}</strong>
              </div>
            </Column>
          </Row>
          <Row alignItems="center">
            <Column xs={50}>
              <Quantity
                size={0.6}
                value={quantity}
                onChange={handleQuantityChange}
              />
            </Column>
            <Column xs={50} alignItems="flex-end">
              <Price value={product.netPrice * quantity} />
            </Column>
          </Row>
        </div>
      ) : null}
    </ListItem>
  );
};

import React, { useState } from 'react';
import {
  Card,
  CardBody,
  CardImage,
  Column,
  Row,
} from '@nimles/react-web-components';
import { Link } from 'gatsby-plugin-react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';
import styled from '@emotion/styled';
import { getImageUrl } from '../../../utils';

const OrganizationLink = styled(Link)`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const Description = styled.div`
  flex: 1 0 auto;
  position: relative;
  font-size: 14px;
  line-height: 18px;
  max-height: 85px;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.surface.onColor};

  &::after {
    content: '';
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      transparent 100%
    );
    display: block;
    position: absolute;
    height: 10px;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

const Name = styled.h4`
  font-size: 16px;
  margin: 0 0 10px;
  font-family: 'Lato', sans-serif;
`;

const Location = styled.h5`
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 12px;
  font-family: 'Lato', sans-serif;
`;

export const OrganizationCard = ({ organization }) => {
  const {
    name,
    description,
    bannerId,
    address: { city },
    distance,
  } = organization;
  const [] = useState(false);

  return (
    <OrganizationLink to={`/${organization.uniqueName}`}>
      <Card flex>
        <CardImage src={getImageUrl(bannerId, 480)} alt={name} ratio={1.5} />
        <CardBody>
          <Name>{name}</Name>
          <Location>
            <Row>
              <Column flex>
                <div>
                  <FontAwesomeIcon icon={faMapMarker} /> {city}
                </div>
              </Column>
              {distance && (
                <Column>
                  {distance === Number.MAX_SAFE_INTEGER
                    ? ''
                    : parseInt(distance) + ' km'}
                </Column>
              )}
            </Row>
          </Location>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </CardBody>
      </Card>
    </OrganizationLink>
  );
};

import React from 'react';
import styled from '@emotion/styled';
import { CartContext } from '../context/cartContext';
import { SvgCart } from '../../svg/SvgCart';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/types';
import { CartModel } from '@nimles/models';
import { State } from '@nimles/react-redux';

const Button = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: none;

  svg {
    height: 30px;
    width: 30px;
  }
`;

export const CartButton = () => {
  const { selected: cart } = useSelector<RootState, State<CartModel>>(
    ({ carts }) => carts
  );

  let quantity = cart?.items?.reduce((sum, item) => sum + item.quantity, 0);

  return (
    <CartContext.Consumer>
      {({ isCartOpen, setCartOpen }) => (
        <Button onClick={() => setCartOpen((prev) => !prev)}>
          <SvgCart />
          {quantity}
        </Button>
      )}
    </CartContext.Consumer>
  );
};

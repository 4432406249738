import React, { useContext, useMemo } from 'react';
import { I18nextContext } from 'gatsby-plugin-react-i18next';

export const Price = ({ value, ...props }) => {
  const { language } = useContext(I18nextContext);

  const numberFormatter = useMemo(
    () =>
      Intl.NumberFormat(language, {
        style: 'currency',
        currency: 'SEK',
      }),
    [language]
  );

  return <div {...props}>{numberFormatter.format(value)}</div>;
};

/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FC, useEffect } from 'react';
import Helmet from 'react-helmet';
import { ThemeProvider } from '@emotion/react';
import { defaultTheme, HeaderProvider } from '@nimles/react-web-components';

import { HeaderLayout } from './header/HeaderLayout';
import { GlobalStyles } from './globalStyles/GlobalStyles';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/types';
import { loadCurrentUser, loadOrganizations } from '@nimles/react-redux';
import { FooterLayout } from './footer/FooterLayout';
import { configuration } from '@nimles/rest-api';
import { graphql, useStaticQuery } from 'gatsby';
import { InitElements } from './elements/InitElements';
import { LayoutModel } from '@nimles/models';
import ApolloClient from 'apollo-boost';
import fetch from 'isomorphic-fetch';
import { ApolloProvider, gql } from '@apollo/react-hooks';
import queryString from 'query-string';
import { CustomTheme } from '../theme';
import { enableDraft } from '../redux/draft';
import { requestUserPosition } from '../redux/geo';
import { CartProvider } from '../components/cart/context/cartContext';
import { CartMenu } from '../components/cart/menu/CartMenu';

export const client: any = new ApolloClient({
  uri: 'https://api.nimles.com/cms/public/graphql',
  fetch,
});

export const layoutQuery = gql`
  query GetLayout($tenantId: Uuid!, $id: Uuid!) {
    layout(tenantId: $tenantId, id: $id, draft: true) {
      id
      organizationId
      layoutType
      elements {
        id
        type
        content
        contentLocalized
        contentId
        properties
        children {
          id
          type
          content
          contentLocalized
          contentId
          properties
          children {
            id
            type
            content
            contentLocalized
            contentId
            properties
            children {
              id
              type
              content
              contentLocalized
              contentId
              properties
              children {
                id
                type
                content
                contentLocalized
                contentId
                properties
                children {
                  id
                  type
                  content
                  contentLocalized
                  contentId
                  properties
                  children {
                    id
                    type
                    content
                    contentLocalized
                    contentId
                    properties
                    children {
                      id
                      type
                      content
                      contentLocalized
                      contentId
                      properties
                      children {
                        id
                        type
                        content
                        contentLocalized
                        contentId
                        properties
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

config.autoAddCss = false;

const Main = styled.main`
  min-height: 80vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`;

const Draft = styled.div`
  position: fixed;
  top: 0;
  left: 0%;
  z-index: 99999;
  background-color: red;
  color: #fff;
  padding: 5px 10px;
`;

interface MetaProps {
  title?: string;
  description?: string;
  keywords?: string;
  image?: string;
}

interface Props {
  header?: boolean;
  headerAlwaysOpen?: boolean;
  footer?: boolean;
  meta?: MetaProps;
  location: Location;
}

const Layout: FC<Props> = ({
  children,
  header,
  footer,
  headerAlwaysOpen,
  meta,
  location,
}) => {
  const { protocol, host } = location;
  const dispatch = useDispatch();
  const accessToken = useSelector<RootState, string>(
    ({ auth }) => auth.accessToken
  );

  const { draft }: any = location
    ? queryString.parse(location.search)
    : { draft: false };
  const isDraft = useSelector<RootState, boolean>(({ draft }) => draft);

  useEffect(() => {
    if (draft && !isDraft) {
      dispatch(enableDraft());
    }
  }, [draft, isDraft]);

  useEffect(() => {
    dispatch(requestUserPosition(true));
  }, []);

  const data = useStaticQuery(graphql`
    query LayoutQuery {
      cms {
        layouts {
          edges {
            node {
              id
              layoutType
              elements {
                ...NimlesCMS_ElementPublicViewModelFragment
                children {
                  ...NimlesCMS_ElementPublicViewModelFragment
                  children {
                    ...NimlesCMS_ElementPublicViewModelFragment
                    children {
                      ...NimlesCMS_ElementPublicViewModelFragment
                      children {
                        ...NimlesCMS_ElementPublicViewModelFragment
                        children {
                          ...NimlesCMS_ElementPublicViewModelFragment
                          children {
                            ...NimlesCMS_ElementPublicViewModelFragment
                            children {
                              ...NimlesCMS_ElementPublicViewModelFragment
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const layouts: LayoutModel[] = data.cms.layouts.edges.map(({ node }) => node);

  const headerLayout = layouts.find(
    ({ layoutType }) => layoutType === 'Header'
  );

  const footerLayout = layouts.find(
    ({ layoutType }) => layoutType === 'Footer'
  );

  useEffect(() => {
    if (accessToken) {
      dispatch(loadCurrentUser());
      dispatch(loadOrganizations());
    }
  }, [accessToken]);

  useEffect(() => {
    configuration.urls.resetPassword = `${protocol}//${host}/resetpassword`;
  }, [protocol, host]);

  const theme: CustomTheme = {
    ...defaultTheme,
    banner: {
      primary: { color: '#efefef', onColor: '#000000' },
      secondary: { color: '#efefef', onColor: '#000000' },
    },
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <InitElements>
          <HeaderProvider>
            <CartProvider>
              <Helmet>
                <html lang="en" />
                <title>{meta?.title ?? process.env.GATSBY_TITLE}</title>
                <meta name="description" content={meta?.description} />
                <meta
                  property="og:title"
                  content={meta?.title ?? process.env.GATSBY_TITLE}
                />
                <meta property="og:description" content={meta?.description} />
                <meta property="og:image" content={meta?.image} />
              </Helmet>

              <Main>{children}</Main>

              <ApolloProvider client={client}>
                {header ? (
                  <HeaderLayout
                    alwaysOpen={headerAlwaysOpen}
                    location={location}
                    layout={headerLayout}
                  />
                ) : null}
                {footer ? (
                  <FooterLayout location={location} layout={footerLayout} />
                ) : null}
                {isDraft || draft ? <Draft>Draft</Draft> : null}
                <CartMenu />
              </ApolloProvider>
            </CartProvider>
          </HeaderProvider>
        </InitElements>
      </ThemeProvider>
    </div>
  );
};

export default Layout;
